<template>
  <div>
    <div class="d-flex mb-3 justify-content-center">
      <div class="d-flex justify-content-center align-items-center">
        <div
          class="rounded bg-primary d-flex icon align-items-center justify-content-center mr-2 text-white font-weight-bold"
        >
          1
        </div>
        Create Order
        <feather-icon icon="ChevronRightIcon" size="35" class="mx-2" />
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <div
          class="rounded bg-primary d-flex icon align-items-center justify-content-center mr-2 text-white font-weight-bold"
        >
          2
        </div>
        Key in Details
        <feather-icon icon="ChevronRightIcon" size="35" class="mx-2" />
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <div
          class="rounded notActive d-flex icon align-items-center justify-content-center mr-2 text-white font-weight-bold"
        >
          3
        </div>
        Finalize Order
        <feather-icon icon="ChevronRightIcon" size="35" class="mx-2" />
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <div
          class="rounded notActive d-flex icon align-items-center justify-content-center mr-2 text-white font-weight-bold"
        >
          4
        </div>
        Proceed Payment
      </div>
    </div>
    <b-card>
      <div class="d-flex justify-content-center align-items-center">
        <div
          class="rounded-circle icon d-flex icon align-items-center justify-content-center mr-1"
        >
          <feather-icon icon="CalendarIcon" size="35" color="white" />
        </div>
        <h3 class="mr-5 text">Pick Up Date</h3>
        <div class="w-75">
          <b-form-group>
            <p
              type="text"
              class="form-control mr-2 mt-1 d-flex align-items-center"
            >
              {{ form.pickup_datetime }}
            </p>
          </b-form-group>
        </div>
      </div>
    </b-card>
    <b-card>
      <div class="d-flex justify-content-center align-items-center">
        <div
          class="rounded-circle icon d-flex icon align-items-center justify-content-center mr-1"
        >
          <feather-icon icon="TruckIcon" size="35" color="white" />
        </div>
        <h3 class="mr-5 text">Origin Point</h3>
        <div class="w-75">
          <p
            type="text"
            class="form-control mr-2 mt-1 d-flex align-items-center"
          >
            {{ form.user_id.name }} | {{ form.user_id.branch.address }}
          </p>
        </div>
      </div>
    </b-card>
    <b-card>
      <div class="d-flex justify-content-center">
        <div
          class="rounded-circle icon d-flex icon align-items-center justify-content-center mr-1"
        >
          <feather-icon icon="HomeIcon" size="35" color="white" />
        </div>
        <h3 class="mr-5 text">Sender's Details</h3>
        <div class="w-75">
          <div class="d-flex mb-2">
            <p type="text" class="form-control mr-2 d-flex align-items-center">
              {{ form.sender_first_name }}
            </p>
            <p type="text" class="form-control d-flex align-items-center">
              {{ form.sender_last_name }}
            </p>
          </div>
          <div class="mb-2">
            <p type="text" class="form-control d-flex align-items-center">
              {{ form.sender_phone_number }}
            </p>
          </div>
          <div class="d-flex mb-2">
            <p type="text" class="form-control mr-2 d-flex align-items-center">
              {{ form.sender_unit_number }}
            </p>
            <p type="text" class="form-control d-flex align-items-center">
              {{ form.sender_address_line_1 }}
            </p>
          </div>
          <div class="mb-2">
            <p type="text" class="form-control d-flex align-items-center">
              {{ form.sender_address_line_2 }}
            </p>
          </div>
          <div class="d-flex mb-2">
            <p type="text" class="form-control mr-2 d-flex align-items-center">
              {{ form.sender_postcode }}
            </p>
            <p type="text" class="form-control d-flex align-items-center">
              {{ form.sender_area }}
            </p>
          </div>
          <div class="d-flex mb-2">
            <p type="text" class="form-control mr-2 d-flex align-items-center">
              {{ form.recipient_state }}
            </p>
            <p type="text" class="form-control d-flex align-items-center">
              {{ form.recipient_country }}
            </p>
          </div>
        </div>
      </div>
    </b-card>
    <b-card>
      <div class="d-flex justify-content-center">
        <div
          class="rounded-circle icon d-flex icon align-items-center justify-content-center mr-1"
        >
          <feather-icon icon="HomeIcon" size="35" color="white" />
        </div>
        <h3 class="mr-5 text">Recipient's Details</h3>
        <div class="w-75">
          <div class="d-flex mb-2">
            <p type="text" class="form-control mr-2 d-flex align-items-center">
              {{ form.recipient_first_name }}
            </p>
            <p type="text" class="form-control d-flex align-items-center">
              {{ form.recipient_last_name }}
            </p>
          </div>
          <div class="mb-2">
            <p type="text" class="form-control d-flex align-items-center">
              {{ form.recipient_phone_number }}
            </p>
          </div>
          <div class="d-flex mb-2">
            <p type="text" class="form-control mr-2 d-flex align-items-center">
              {{ form.sender_unit_number }}
            </p>
            <p type="text" class="form-control d-flex align-items-center">
              {{ form.sender_address_line_1 }}
            </p>
          </div>
          <div class="mb-2">
            <p type="text" class="form-control d-flex align-items-center">
              {{ form.sender_address_line_2 }}
            </p>
          </div>
          <div class="d-flex mb-2">
            <p type="text" class="form-control mr-2 d-flex align-items-center">
              {{ form.sender_postcode }}
            </p>
            <p type="text" class="form-control d-flex align-items-center">
              {{ form.sender_area }}
            </p>
          </div>
          <div class="d-flex mb-2">
            <p type="text" class="form-control mr-2 d-flex align-items-center">
              {{ form.recipient_state }}
            </p>
            <p type="text" class="form-control d-flex align-items-center">
              {{ form.recipient_country }}
            </p>
          </div>
        </div>
      </div>
    </b-card>
    <b-card>
      <div class="d-flex justify-content-center">
        <div
          class="rounded-circle icon d-flex icon align-items-center justify-content-center mr-1"
        >
          <feather-icon icon="BoxIcon" size="35" color="white" />
        </div>
        <h3 class="mr-5 text">Product Details</h3>
        <div class="w-75">
          <div class="d-flex mb-2">
            <p class="form-control mr-2 d-flex align-items-center">
              {{ form.item_weight }}
            </p>
            <p class="form-control mr-2 d-flex align-items-center">
              {{ form.quantity }}
            </p>
            <p class="form-control mr-2 d-flex align-items-center">
              {{ form.remarks }}
            </p>
          </div>
        </div>
      </div>
    </b-card>
    <div class="d-flex justify-content-center align-items-center mt-3">
      <b-button
        @click="onBack"
        variant="gradient-primary"
        v-b-modal.modal-center
        class="box-shadow-1 px-5 mr-3 w-25"
        >Back</b-button
      >
      <b-button
        @click="onSubmit"
        variant="gradient-primary"
        v-b-modal.modal-center
        class="box-shadow-1 px-5 w-25"
        >Confirm Delivery</b-button
      >
    </div>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import { VueGoodTable } from "vue-good-table";
import axios from "@axios";

export default {
  components: {
    flatPickr,
    VueGoodTable,
  },
  data() {
    return {
      form: {},
      address: [],
      addressName: "",
      form2: {},
    };
  },
  created() {
    this.form = this.$route.params.data;
  },
  methods: {
    onSubmit() {
      this.$router.push({
        name: "dhl-order-paymentmethod",
        params: { data: this.form },
      });
    },
    onBack() {
      this.$router.push({
        name: "dhl-order-create",
        params: { data: this.form },
      });
    },
  },
  mounted() {
    this.$http.get(`branch`).then((response) => {
      this.branch = response.data.data;
    });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";

.icon {
  background-color: #0f4392;
  height: 50px;
  width: 50px;
}
.text {
  width: 15rem;
}
.notActive {
  background-color: rgba(211, 18, 100, 0.58);
}
.buttonDHL {
  width: 10rem;
  background-color: #29cc97;
}
</style>
